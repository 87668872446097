export const mappings = [

  { label: 'Account Reference', field: 'account_code' },
  { label: 'Account Name', field: 'company_name' },
  { label: 'Street 1', field: 'address_line_1' },
  { label: 'Street 2', field: 'address_line_2' },
  { label: 'Town', field: 'address_town' },
  { label: 'County', field: 'address_county' },
  { label: 'Postcode', field: 'address_post_code' },
  { label: 'Contact Name', field: '' },
  { label: 'Telephone Number', field: 'tel' },
  { label: 'Fax Number', field: 'fax' },
  { label: 'Analysis 1', field: '' },
  { label: 'Analysis 2', field: '' },
  { label: 'Analysis 3', field: '' },
  { label: 'Department', field: '' },
  { label: 'VAT Reg No', field: 'vatnumber' },
  { label: 'MTD Turnover', field: '' },
  { label: 'YTD Turnover', field: '' },
  { label: 'Last Year', field: '' },
  { label: 'Credit Limit', field: '' },
  { label: 'Terms Text', field: '' },
  { label: 'Due Days', field: '' },
  { label: 'Settlement Discount', field: '' },
  { label: 'Default Nominal', field: '' },
  { label: 'Tax Code', field: '' },
  { label: 'Trade Contact', field: '' },
  { label: 'Telephone 2', field: '' },
  { label: 'EMail', field: 'email' },
  { label: 'WWW', field: '' },
  { label: 'Discount Rate', field: '' },
  { label: 'Payment Due Days', field: '' },
  { label: 'Terms Agreed?', field: '' },
  { label: 'Bank Name', field: '' },
  { label: 'Bank Address 1', field: '' },
  { label: 'Bank Address 2', field: '' },
  { label: 'Bank Address 3', field: '' },
  { label: 'Bank Address 4', field: '' },
  { label: 'Bank Address 5', field: '' },
  { label: 'Bank Account Name', field: '' },
  { label: 'Bank Sort Code', field: '' },
  { label: 'Bank Account No', field: '' },
  { label: 'Bank BACS Ref', field: '' },
  { label: 'Online Payments?', field: '' },
  { label: 'Currency No', field: '' },
  { label: 'Restrict Mailing?', field: '' },
  { label: 'Date Account Opened', field: '' },
  { label: 'Next Credit Review', field: '' },
  { label: 'Last Credit Review', field: '' },
  { label: 'Account Status', field: '' },
  { label: 'Can Apply Charges?', field: '' },
  { label: 'Country Code', field: '' },
  { label: 'Priority Trader?', field: '' },
  { label: 'Override Stock Tax?', field: '' },
  { label: 'Override Stock Nom?', field: '' },
  { label: 'Bank Additional 1', field: '' },
  { label: 'Bank Additional 2', field: '' },
  { label: 'Bank Additional 3', field: '' },
  { label: 'Bank IBAN', field: '' },
  { label: 'Bank BIC Swift', field: '' },
  { label: 'Bank Roll Number', field: '' },
  { label: 'Report Password', field: '' },
  { label: 'DUNS Number', field: '' },
  { label: 'Payment Method', field: '' },
  { label: 'Letters Via Email?', field: '' }
]
